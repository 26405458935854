.socials {
  padding: 30px 0px;
  .socialIcons {
    display: flex;
    flex-direction: row;
    width: 100vw;
    justify-content: space-evenly;

    img {
      height: 25px;
    }
  }
}
