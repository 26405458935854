.profileImg {
  margin-top: 30px;
  border-radius: 50%;
  height: 150px;
}
.header2 {
  color: #6699ff;
  font-size: 48px;
  line-height: 65px;
  font-weight: bold;
}
