.App {
  color: #bdbdbd;
  height: 100%;
}

.viewArea {
  display: flex;
  min-height: 100%;
}

.viewContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  line-height: 5vh;
  height: 70vh;
  overflow: auto;
  overflow-x: hidden;
  width: 100%;
  padding: 5vw;
}

@media only screen and (max-width: 600px) {
  .viewContent {
    justify-content: flex-start;
  }
  .description {
    margin: none;
  }
}

.header1 {
  color: #6699ff !important;
  font-weight: bold;
}

.description {
  margin: 0 15px;
  color: #bdbdbd;
}

.footer {
  position: fixed;
  bottom: 0;
  margin-top: -50px;
  height: 175px;
}
.footerContent {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.footerNav {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100vw;
  padding: 30px 0px;
}

.solid {
  border-color: #bdbdbd;
  width: 90vw;
}

.linkStyle {
  color: #bdbdbd;
  padding: 5px;
}
